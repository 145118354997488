import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Larr, Rarr } from '~components/Svg'
import Image from '~components/Image'
import Section from '~components/Section'
import MenuTile from '~components/MenuTile'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'
import ArticleTile from '~components/ArticleTile'
import { useSiteState } from '~context/siteContext'
import { AnimatePresence, motion } from 'framer-motion'
import MenuTagTile from '~components/MenuTagTile'
import SanityLink from '~components/SanityLink'

const MobileMenu = ({ className, menuData }) => {

	const [siteState, setSiteState] = useSiteState()

	const levelOne = [
		{	title: 'directory', _key: 'directory'},
		...menuData.menuItems,
		{ title: 'focus', _key: 'focus'},
		{ title: 'journal', _key: 'journal'}
	]

	return (
		<Wrap className={className}>
			<LevelOne>
				{levelOne.map(view => (
					<ViewButton 
						key={view._key} 
						onClick={()=> setSiteState(prevState => ({
								...prevState,
								menuView: view.title.toLowerCase(),
							}))}
						className='caps'
					>
						<CircleImage image={'#'}/>
						{view.title}
						<RightArrow />
					</ViewButton>
				))}
				<AboutLink to='/about' className='caps'>about</AboutLink>
			</LevelOne>
				<AnimatePresence exitBeforeEnter>
					<LevelTwo 
						key={siteState.menuView}
						initial={{ x: '100%' }}
						animate={{ x: '0%' }}
						exit={{ x: '100%' }}
						transition={{ duration: 0.3 }}
					>
							{siteState.menuView  &&
								<ViewTitle>
									<Back onClick={()=> setSiteState(prevState => ({
											...prevState,
											menuView: undefined,
										}))}>
										<Larr/>
									</Back>
									<h6 className='caps'>{siteState.menuView}</h6>
								</ViewTitle>
							}
							{siteState.menuView  === 'directory' &&
							<>
								<MenuGrid>
									<MenuHeading className='caps'>categories</MenuHeading>
									{menuData?.featuredCollections?.map(collection => (
										<MenuLink 
											key={collection.text} 
											className='small'
											link={collection.link}
										>{collection.text}
										</MenuLink>
									))}
									{menuData?.featuredCollectionsBelow && <Line />}
									{menuData?.featuredCollectionsBelow?.map(collection => (
										<MenuLink 
											key={collection.text} 
											className='small'
											link={collection.link}
										>{collection.text}
										</MenuLink>
									))}
								</MenuGrid>
								<MenuGrid>
									<MenuHeadingLink className='caps' to={'/a-z'}>Brands A – Z</MenuHeadingLink>
								</MenuGrid>
								<MenuGrid>
									<MenuHeading className='caps'>Featured Brands</MenuHeading>
									{menuData?.featuredBrands?.map(brand => (
										<MobileMenuTile key={brand._id} content={brand} link={{linkType: 'internal', document: brand}}></MobileMenuTile>
									))}
								</MenuGrid>
								<MenuGrid>
								<BrandEditLinks>
									<MenuHeading className='caps'>Brand Edits</MenuHeading>
									{menuData?.featuredBrandEdits.map(edit => (
										<CircleLink key={edit.slug?.current} link={edit.link} className='small'>
											<CircleImage image={edit?.image} aspectRatio={1}/>
											{edit.title}
										</CircleLink>
									))}
								</BrandEditLinks>
								</MenuGrid>
							</>
							}
							{menuData?.menuItems?.map(item => (
								<React.Fragment key={item._key}>
									{siteState.menuView === item.title.toLowerCase() &&
										<MenuGrid>
											<MenuHeading className='caps'>{item.title}</MenuHeading>
											{item?.subItems?.slice(0,8).map(subItem => (
												<MobileMenuTile 
												content={subItem} 
												key={subItem._key}/>
											))
											}
										</MenuGrid>
									}
								</React.Fragment>
							))}
							{siteState.menuView  === 'focus' &&
								<>
									<MenuGrid>
										<MenuHeading className='caps'>Inclusivity</MenuHeading>
										{menuData?.featuredInclusivity.map(button => (
											<MenuLink key={button.text} className='small' link={button.link}>{button.text}</MenuLink>
										))}
									</MenuGrid>
									<MenuGrid>
										<MenuHeading className='caps'>Values</MenuHeading>
										{menuData?.featuredValues?.map(brand => (
											<TagTile key={brand._id} content={brand} aspectRatio={0.713}></TagTile>
										))}
									</MenuGrid>
									<MenuGrid>
									<BrandEditLinks>
										<MenuHeading className='caps'>Interest</MenuHeading>
										{menuData?.featuredInterest.map(tile => (
											<CircleLink key={tile.slug?.current} link={tile.link} className='small'>
												<CircleImage image={tile?.image} aspectRatio={1}/>
												{tile.title}
											</CircleLink>
										))}
									</BrandEditLinks>
									</MenuGrid>
								</>
							}
							{siteState.menuView === 'journal' &&
								<>
								<MenuGrid>
									<MenuHeading className='caps'>Explore</MenuHeading>
									{menuData.featuredColumns?.map(doc => (
										<MenuLink key={doc.title} className='small' link={{linkType: 'internal', document: doc}}>{doc.title}</MenuLink>
									))}
									<MenuLink key='journal' className='small' href='/journal'>View All</MenuLink>
								</MenuGrid>
								<MenuGrid>
								<MenuHeading className='caps' css={css`padding-bottom: 10px;`}>Featured stories</MenuHeading>
									{menuData.featuredArticles?.slice(0,3).map(tile => (
										<MenuArticleTile key={tile.title} content={tile} aspectRatio={1.612} menu/>
									))}
								</MenuGrid>
								</>
							}
				</LevelTwo>
			</AnimatePresence>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
	overflow-x: hidden;
`
const LevelOne = styled.div`
`
const ViewButton = styled.button`
	padding: 0 15px;
	border-bottom: var(--black);
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid var(--black);
	height: 55px;
`
const CircleImage = styled(Image)`
	width: 35px;
	margin-right: 12px;
	> div{
		border-radius: 50px;
		overflow: hidden;
	}
`
const RightArrow = styled(Rarr)`
	height: 8px;
	width: 23px;
	justify-self: end;
`
const AboutLink = styled(Link)`
	border-bottom: 1px solid var(--black);
	height: 55px;
	display: grid;
	align-items: center;
	padding: 0 15px;
`
const LevelTwo = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: var(--white);
`
const ViewTitle = styled.div`
	padding: 0 15px;
	height: 40px;
	display: grid;
	grid-template-columns: 1fr max-content 1fr;
	background-color: var(--yellow);
	align-items: center;
	border-bottom: 1px solid var(--black);
`
const Back = styled.button`
	justify-self: start;
	height: 8px;
	width: 23px;
	> svg{
		display: block;
	}
`
const MenuGrid = styled(Section)`
	margin-top: 22px;
	border-bottom: 1px solid var(--black);
	padding-bottom: 22px;
`
const MenuHeading = styled.h6`
	grid-column: span 12;
	margin-bottom: 10px;
	font-weight: 600;
`
const MenuHeadingLink = styled(Link)`
	grid-column: span 12;
	font-weight: 600;
`
const MenuLink = styled(SanityLink)`
	grid-column: span 12;
	margin-bottom: 10px;
	:last-of-type{
		margin-bottom: 0px;
	}
`
const BrandEditLinks = styled.div`
	grid-column: span 12;
`
const CircleLink = styled(SanityLink)`
	display: grid;
	grid-template-columns: max-content 1fr;
	margin-bottom: 12px;
	align-items: center;
`
const MobileMenuTile = styled(MenuTile)`
	grid-column: span 6;
	margin-bottom: 18px;
`
const MenuArticleTile = styled(ArticleTile)`
	grid-column: span 12;
	margin-bottom: 25px;
`
const TagTile = styled(MenuTagTile)`
	grid-column: span 6;
`

const Line = styled.div`
	width: 100%;
	height: 1px;
	background: var(--black);
	margin-top: 12px;
	margin-bottom: 22px;
	grid-column: span 12;
`

MobileMenu.propTypes = {
	className: PropTypes.string,
	menuData: PropTypes.object,
}

export default MobileMenu